<template>
  <div class="wrap">
    <div class="search-container">
      <el-form ref="form" :model="form" label-width="80px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="商品名称" prop="name">
              <el-input
                clearable
                placeholder="请输入商品名称"
                v-model="form.name"
                :maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="storeId" label="仓库">
              <el-select clearable v-model="form.storeId" style="width: 100%">
                <el-option v-for="item in wareHouseList" :key="item.id" :label="item.venueName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="编码" prop="productCode">
              <el-input
                clearable
                placeholder="请输入编码"
                v-model="form.productCode"
                :maxlength="8"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="text-right">
            <el-button
              type="primary"
              @click="searchEvent"
              class="search-button"
              >{{ $t("search") }}</el-button
            >
            <el-button type="primary" @click="cleanForm" class="clean-button">{{
              $t("clean")
            }}</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight - 20"
        style="width: 100%"
        border
        @sort-change="sort_post_change"
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="index" align="center" label="序号" width="55">
        </el-table-column>
        <el-table-column type="selection" align="center" width="55">
        </el-table-column>
        <el-table-column
          prop="code"
          align="left"
          min-width="80"
          label="编码"
        >
        </el-table-column>
        <el-table-column
          prop="venueName"
          align="left"
          min-width="120"
          label="仓库"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          align="left"
          header-align="center"
          min-width="220"
          label="商品名称"
        >
        </el-table-column>
        <el-table-column
          prop="stockQty"
          align="center"
          min-width="110"
          show-overflow-tooltip
          label="库存数量"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="unitText"
          min-width="80"
          show-overflow-tooltip
          label="单位"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="costPrice"
          min-width="120"
          label="成本（元）"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="stockPrice"
          min-width="120"
          label="库存金额（元）"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="pageination-wrapper">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import mixin from "@/mixins/index";
import IndexClass from "./indexClass.js";
import apis from "@/apis";
import { changeInfo } from "@/utils/index";

export default {
  mixins: [mixin],
  components: {
  },
  data() {
    return {
      form: new IndexClass("form"),
      tableData: [],
      wareHouseList: [],
    };
  },
  computed: {
    ...mapState(["Language", "DROPDOWNBOX"]),
  },
  mounted() {
    this.getWareHouse()
  },
  methods: {
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http.post(apis.venueProductStockQuery, this.form).then((res) => {
        console.log(55555)
        if (res.data.code === 0) {
          this.tableData = res.data.rows.map((item) => {
            item.unitText = changeInfo(item.unit, this.DROPDOWNBOX.PRODUCT_UNIT, 'value', 'label')
            return item
          });
          this.form.total = res.data.total;
        }
      });
    },
    // 获取仓库
    getWareHouse() {
      this.$http.post(apis.venuevManagementList, {}).then((res) => {
        if (res.data.code === 0) {
          this.wareHouseList = res.data.rows
        }
      })
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new IndexClass("form");
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.search-wrapper {
  width: 100%;
}
.pageination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
